<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemIdList">
              <input-plus
                v-model="searchForm.itemIdList"
                valueType="Array"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workNoList">
              <input-plus
                v-model="searchForm.workNoList"
                valueType="Array"
                placeholder="任务单号"
                title="任务单号"
                label="任务单号"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--2" prop="callTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="联系时间"
                ref="effectiveDatePicker"
                v-model="searchForm.callTime"
                title="来去电时间"
                :picker-options="pickerOptions('searchForm.callTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="联系时间（始）"
                end-placeholder="联系时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_fill" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
        >
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <play-video
      :recordUrl="curSrc"
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
    ></play-video>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getTelephoneRecord, exportTelephoneRecord } from "@/api";
import { exportData } from "@/utils/download";
import PlayVideo from "@/components/playVideo.vue";
import inputPlus from "@/components/inputPlus";
const nowTime = new Date().getTime();
export default {
  name: "telephoneRecord",
  components: { PlayVideo, inputPlus },
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      total: 0,
      searchForm: {
        itemIdList: [],
        workNoList: [], //客户名称
        callTime: [nowTime - 2592000000], //创建日期
        callTimeBegin: nowTime - 2592000000, //创建开始日期 默认一个月
        callTimeEnd: "", //查询结束时间时间戳类型
        pageIndx: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "workNo",
          fieldLabel: "任务单号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleTaskId",
          display: true,
          fieldLabel: "调度任务ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "调度信息ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          display: true,
          fieldLabel: "联系人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerPhone",
          display: true,
          fieldLabel: "被联系人号码",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "被联系人名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerCallTypeStr",
          display: true,
          fieldLabel: "来去电类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callTime",
          display: true,
          fieldLabel: "联系时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "durationStr",
          display: true,
          fieldLabel: "通话时长",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "callResultStr",
          display: true,
          fieldLabel: "通话结果",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      curSrc: "", //当前播放录音地址
      dialogVisible: false,
      refTable: "tableList",
      queryListAPI: getTelephoneRecord
    };
  },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.callTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.callTime = this.searchForm.callTime.reverse();
        }
        this.searchForm.callTimeBegin = val[0] || "";
        this.searchForm.callTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取页面操作权限
    getOperateList(row) {
      let operates = [];
      if (row.recordAccessUrl) {
        operates = [
          {
            label: "播放录音",
            id: 1
          }
        ];
      }
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogVisible = true;
        this.curSrc = row.recordAccessUrl;
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 导出
    handleExport() {
      exportData(this, exportTelephoneRecord);
    }
  }
};
</script>
<style lang="scss" scoped></style>
